import React, {useEffect } from "react"
import TranslateIcon from '../images/translate-white.svg'

const GoogleTranslate = () => {

 const googleTranslateElementInit = () => {
   new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element')
  }

  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, [])

  return (
    <div className="translate">
      <img className="top-bar-icon" src={TranslateIcon} alt="translate" />
      <div id="google_translate_element"></div>
    </div>
  );
};

export default GoogleTranslate