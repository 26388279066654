import React, { Component } from "react"
import { Link } from "gatsby"

class LinkUrl extends Component {

  render(props) {

    const link = this.props.link
    const allPages = this.props.allLinks
    const title = this.props.title
    const description = this.props.description
    var pagesArr = []
    allPages && allPages.edges && allPages.edges.map((page,key) => (
      page.node.context.drupal_nid !== null ? (
        pagesArr[page.node.context.drupal_nid ] = page.node.path
      ) : (
        null
      )
    ))


    return (
      <>
      {link.indexOf('http') !== -1 ? (
        <a href={link.replace('internal:','')} rel="noreferrer" target="_blank" className={description !== null ? description : ""}>{title}</a>
      ) : (
        link && link.indexOf(':node/') !== -1 && pagesArr[link.split('/')[1]] !== undefined ? (
          <Link to={pagesArr[link.split('/')[1]]} className={description !== null ? description : ""}>
            {title}
          </Link>
        ) : (
          <Link to={link.replace('internal:','')} className={description !== null ? description : ""}>
            {title}
          </Link>
        )
      )}

      </>
      )
  }

}

export default LinkUrl

export const query = graphql`
    fragment SitePageConnectionFragment on SitePageConnection {
      totalCount
      edges {
        node {
          context {
            drupal_nid
          }
          path
        }
      }
    }
`