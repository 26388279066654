import React, { Component } from "react"

class EocAlert extends Component {
  constructor(props)
  {
   super(props) ;
   this.state = { data: null};
  }
  
  componentDidMount()
  {
    const loadJSON = async() => {
      try{
        const response = await fetch('https://eservice2.pwcva.gov/apps/emdata/alert.json');
        const data = await response.json();
        this.setState({data});

      }
      catch(error){
        console.log('Failed to load JSON', error);
      }
    };
    loadJSON();
  }
  
  
  render() {

    const { data } = this.state;

    return (
      <div>
        

        {data && data.status == true && ( 
          <div className="eoc-alert">
              <span className="eoc-alert-msg">{data.msg}</span> <a className="eoc-alert-btn" href={data.url}>READ MORE</a>
              
          </div>
        )}
      </div> 
      )
  }
}

export default EocAlert
