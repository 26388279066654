import React, { Component } from "react"
import SearchIcon from '../images/search-white.svg'
import PhoneIcon from '../images/phone-transparent.svg'
import GoogleTranslate from '../components/googletransalte.js'
import Meeting from '../components/meeting.js'
import closeIcon from "../images/close-icon-green.svg"

class BlueBar extends Component {

  render() {

    var show = this.props.showMeeting
    var meeting = this.props.meetingLink

    return (

       <div className="top-bar">
        <div className="container">
          <div className="top-bar-wrp">
            <div className="board-meeting">
              <Meeting />
            </div>

            <div className="search-wrp">
              <img className="top-bar-icon" src={SearchIcon} alt="search" />
              <a className="top-bar-search" title="search icon" aria-label="search icon" href="#">Search</a>
            </div>

            <div>
            <GoogleTranslate />
            </div>

            <div>
              <img className="top-bar-icon" src={PhoneIcon} alt="phone" />
              <a className="telephone" href="tel:7037926000">(703) 792-6000 <span>(TTY: 711)</span></a>
            </div>

          </div>
        </div>
       </div>
      )
  }
}

export default BlueBar
