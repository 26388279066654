import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
var moment = require('moment'); // require
var year = moment().year();

const Footer = () => (

  <footer id="colophon" className="site-footer">
    <div className="external-overlay">
      <div className="external-overlay-content">
        <h2 className="h3">External Link</h2>
        <p>An action you have taken is directing you away from this site to the following address:</p>
        <p className="external-link-disclaimer">example site</p>
        <p>Prince William County may not own or control the contents of this link.</p>
        <a className="close-overlay close-x-button uk-close" aria-label="close icon" title="close icon" href="#"></a>
        <div className="button-wrp"><a className="uk-button green-bgr external-link-sidebar" aria-label="Open link in new window" title="Open link in new window" href="#" target="_blank">Open link in new window</a></div>
        <div className="button-wrp"><a className="uk-button green-bgr close-overlay" aria-label="Cancel" title="Cancel" href="#">Cancel</a></div>
      </div>
    </div>
    <StaticQuery
      query={graphql`
        query {
          blockContentFooterBlock {
            relationships {
              field_footer_logo {
                uri {
                  url
                }
              }
              field_footer_section {
                field_title
                relationships {
                  field_footer_links {
                    field_link {
                      uri
                      title
                    }
                    relationships {
                      field_icon {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          blockContentSocialLinksWithIcons {
            relationships {
              field_link_icon {
                field_link {
                  uri
                  title
                }
                relationships {
                  field_icon {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          blockContentBasic(drupal_internal__id: {eq: 9}) {
            body {
              value
            }
          }
          allMenuLinkContentMenuLinkContent(filter: {enabled: {eq: true}, menu_name: {eq: "footer-bottom"}}) {
            edges {
              node {
                menu_name
                title
                link {
                  uri
                  title
                }
              }
            }
          }
        }
      `}

      render={data => (
        <div>
        <div className="upper-footer">
          <div className="container">
            <div className="uk-grid uk-flex-middle" data-uk-grid>
              <div className="uk-width-auto@m logo-footer">
                <img src={data.blockContentFooterBlock.relationships.field_footer_logo.uri.url} alt="logo footer" />
              </div>
              <div className="uk-width-2-5@m uk-flex-middle footer-contact">
                <h2 dangerouslySetInnerHTML={{ __html: data.blockContentFooterBlock.relationships.field_footer_section[0].field_title }}/>
                <div className="uk-grid" data-uk-grid>
                  <div className="uk-width-2-3@m contact-info">
                    {data.blockContentFooterBlock.relationships.field_footer_section[0].relationships.field_footer_links.map((link, index) => (
                      index < 2 ? (
                        <div key={index}>
                          {link.relationships.field_icon !== null ? (
                            <img src={link.relationships.field_icon.uri.url} alt={"link-1-" + index}/>
                          ) : (
                            null
                          )}
                          <a target="_blank" rel="noreferrer" href={link.field_link.uri.replace('internal:', '')} dangerouslySetInnerHTML={{ __html:link.field_link.title}}/>
                        </div>
                      ) : (
                        null
                      )
                    ))}
                  </div>
                  <div className="uk-width-1-3@m email-info">
                    {data.blockContentFooterBlock.relationships.field_footer_section[0].relationships.field_footer_links.map((link, index) => (
                      index >= 2 ? (
                        <div key={index}>
                          {link.relationships.field_icon !== null ? (
                            <img src={link.relationships.field_icon.uri.url} alt={"link-1-" + index}/>
                          ) : (
                            null
                          )}
                          <a href={link.field_link.uri.replace('internal:', '')}>{link.field_link.title}</a>
                        </div>
                      ) : (
                        null
                      )
                    ))}
                  </div>
                </div>
              </div>
              <div className="uk-width-2-5@m uk-flex-middle footer-resources">
                <h2 dangerouslySetInnerHTML={{ __html: data.blockContentFooterBlock.relationships.field_footer_section[1].field_title }}/>
                <div className="uk-grid uk-child-width-1-2@m" data-uk-grid>
                  {data.blockContentFooterBlock.relationships.field_footer_section[1].relationships.field_footer_links.map((link, index) => (
                    <div key={index}>
                      {link.relationships.field_icon !== null ? (
                        <img src={link.relationships.field_icon.uri.url} alt={"link-1-" + index}/>
                      ) : (
                        null
                      )}
                      <Link to={link.field_link.uri.replace('internal:', '')}>{link.field_link.title}</Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <div className="uk-grid uk-child-width-1-3@m uk-flex-middle" data-uk-grid>
              <div style={{display: `flex`}} className="copyright">&nbsp;&nbsp;&copy;{year} <div style={{marginLeft: `5px`}} dangerouslySetInnerHTML={{ __html: data.blockContentBasic.body.value }}></div></div>
              <ul className="uk-width-1-3@m social-icons">
                {data.blockContentSocialLinksWithIcons.relationships.field_link_icon.map((link, index) => (
                  link.field_link ? (
                    <li key={index}><a href={link.field_link.uri} rel="noreferrer" target="_blank"><img src={link.relationships.field_icon.uri.url} alt={link.field_link.title + " icon"} /></a></li>
                  ) : (
                    null
                  )
                ))}
              </ul>
              <ul className="terms uk-flex-middle">
                {data.allMenuLinkContentMenuLinkContent.edges.map((link, index) => (
                  link.node.link ? (
                    <li key={index}><Link to={link.node.link.uri.replace('internal:', '')}>{link.node.title}</Link></li>
                  ) : (
                    null
                  )
                ))}
              </ul>
            </div>
            <div className="totop">
              <a href="#" aria-label="scroll to top" title="scroll to top" data-uk-totop data-uk-scroll></a>
            </div>
          </div>
        </div>
        </div>
      )}
    />
  </footer>
)
export default Footer
