/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser';

function SEO({ domen, lang, metaData, meta, title, locationOrigin, contentBody,overviewDesc,quickLinks}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }

      fragment paragraph__meta_dataFragment on paragraph__meta_data {
        field_description
        field_title
        relationships {
          field_media_image {
            relationships {
              field_media_image {
                uri {
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  var entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
      };

  var body = null
  var overview = null
  var bodyPlain = null
  var overviewPlain = null


  if(contentBody) {
    body = String(contentBody.processed).replace(/[&<>"'`=\/]/g, function (s) {
          return entityMap[s];
    });
    bodyPlain = String(contentBody.processed).replace(/(<([^>]+)>)/ig, '');
    bodyPlain = String(bodyPlain).replace(/[&<>"'`=\/]/g, function (s) {
          return entityMap[s];
    });
  }

  if(overviewDesc) {
    overview = String(overviewDesc).replace(/[&<>"'`=\/]/g, function (s) {
          return entityMap[s];
    });

    overviewPlain = String(overviewDesc).replace(/(<([^>]+)>)/ig, '');
    overviewPlain = String(overviewPlain).replace(/[&<>"'`=\/]/g, function (s) {
          return entityMap[s];
    });

  }

  var metaDescription, keywords
  if(metaData !== null) {
    metaDescription = metaData.field_description || site.siteMetadata.description
    keywords = metaData.field_title || title
  } else {
    metaDescription = site.siteMetadata.description
    keywords = title
  }

  var creator = "@pwcgov"
  var cardType = "summary"
  var locationOr = ""
  if(locationOrigin) {
    locationOr = locationOrigin
  }

  var metaImage
  var tImage,height,width,tDescription
  if(metaData !== null && metaData.relationships.field_media_image !== null) {
     metaImage = domen+metaData.relationships.field_media_image.relationships.field_media_image.uri.url
     tImage = domen+metaData.relationships.field_media_image.relationships.field_media_image.uri.url
    height = '480'
    width = '480'
  } else {
     metaImage = null
     height = null
     width = null
     tImage = null
  }


  tDescription = metaDescription


  var metaArr = [
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords
        },
        {
          property: `og:title`,
          content: title,
        },

        {
          class:"swiftype",
          name: `SearchTitle`,
          "data-type": `string`,
          content: title,
        },

        {
          property: `og:url`,
          content: locationOr,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: cardType,
        },
        {
          name: `twitter:creator`,
          content: creator,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: tDescription,
        },
      ]
      if(metaImage) {
          metaArr.push(
            {
              property: `og:image`,
              content: metaImage,
            },
            {
              property: `og:image:secure_url`,
              content: metaImage,
            },
            {
              property: `og:image:width`,
              content: width,
            },
            {
              property: `og:image:height`,
              content: height,
            },
          )
      }
      if(tImage) {
        metaArr.push(
          {
            name: `twitter:image`,
            content: tImage,
          }
        )
      }

      if(body) {
        metaArr.push(
          {
            class:"swiftype",
            name: `SearchContent`,
            "data-type": `text`,
            content: body,
          }
        )
      }

      if(bodyPlain) {
        metaArr.push(
          {
            class:"swiftype",
            name: `searchContentPlainText`,
            "data-type": `text`,
            content: bodyPlain,
          }
        )
      }

      if(overviewPlain) {
        metaArr.push(
          {
            class:"swiftype",
            name: `searchContentPlainText`,
            "data-type": `text`,
            content: overviewPlain,
          }
        )
      }

      if(overview) {
        metaArr.push(
          {
            class:"swiftype",
            name: `SearchContent`,
            "data-type": `text`,
            content: overview,
          }
        )
      }

      if(quickLinks) {
        metaArr.push(
          {
            class:"swiftype",
            name: `SearchLinks`,
            "data-type": `text`,
            content: quickLinks,
          }
        )
      }


  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={parse(title)}
      titleTemplate={`%s`}

      meta={metaArr.concat(meta)}

    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
