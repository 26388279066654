import { Component } from "react"
import $ from "jquery"

class GlobalJs extends Component {
  componentDidMount() {
    try {
      this.UIkit = require('uikit/dist/js/uikit')
      this.Icons = require('uikit/dist/js/uikit-icons')
      this.UIkit.use(this.Icons)
    } catch (e) {
      console.error(e)
    }

    $('.open-menu').click(function(e) {
      e.preventDefault();
      if($(this).hasClass('open')) {
        $(this).removeClass('open');
        $('.main-nav-navbar').removeClass('open');
        $('body').removeClass('no-scroll');
      } else {
        $(this).addClass('open');
        $('.main-nav-navbar').addClass('open');
        $('body').addClass('no-scroll');
      }
    });

    var externalLink = '';
    $('a').click(function(e){
      if ($(this).attr('href') != undefined && !$(this).attr('href').includes('pwcgov.org') && !$(this).attr('href').includes('pwcgov.granicus.com') && !$(this).attr('href').includes('prin.na2.iiivega.com') && !$(this).attr('href').includes('pwcgov.libnet.info') && ($(this).attr('href').includes("https://") || $(this).attr('href').includes("http://")) && !$(this).attr('href').includes(document.domain) && !$(this).hasClass('external-link-sidebar') && !$(this).parents('li').hasClass('yt-modal-video')) {
        e.preventDefault();
        externalLink = $(this);
        $('.external-overlay').addClass('active-overlay');
        $('body').css('overflow', 'hidden');
        $('.external-link-disclaimer').text($(this).attr('href'));
        $('.external-link-sidebar').prop("href", $(this).attr('href'));
        setTimeout(function(){ $('.external-link-sidebar')[0].focus() }, 500);
      }
    });

    $('.close-overlay').click(function(e){
      e.preventDefault();
      $('.external-overlay').removeClass('active-overlay');
      $('body').css('overflow', 'auto');
      externalLink.focus();
    });
    $('.external-link-sidebar').click(function(){
      $('.external-overlay').removeClass('active-overlay');
      $('body').css('overflow', 'auto');
      externalLink.focus();
    });


    $('.main-nav-navbar a').click(function() {
      if ($(this).attr('href') == window.location.pathname) {
        $('.main-nav-navbar .uk-navbar-dropdown.uk-open').prev('a')[0].click();
      }
    });
    $('.agency-menu a').click(function(){
      if ($(this).attr('href') == window.location.pathname) {
        $('.departments-menu.open')[0].click();
      }
    });

    $('.site-header a').not('.open-menu').not('.uk-navbar-nav > li > a').not('.site-header [href="#"]').click(function(e) {
      if (window.matchMedia('(max-width: 1024px)').matches) {
        $('body').removeClass('no-scroll');
      }
    });

    this.UIkit.util.on('#events-slider', 'itemshown', function () {
      setTimeout(function(){
        $('.date-year').text($('.uk-active .uk-first-column:first-child .current-date').text())
        $('.uk-slider-items').height($('.uk-active > .uk-grid-small').height() + 'px');
      },100)
    });

    if($('#events-slider').length > 0 && $('.current-slide').length > 0) {
      $('#events-slider').css('visibility','hidden')
      var curentSlide = $('.current-slide')[0].closest('li');
      var showSlider = $('#events-slider li').index(curentSlide);
      var ui = this.UIkit
      ui.slider($('#events-slider')).show(showSlider)
      setTimeout(function(){
        $('#events-slider').css('visibility','visible')
      },1500)
    }

    $('#switcher-tabs li').click(function(){
      window.history.replaceState(null, null, "#"+$(this).attr('id'));
    })

    if($('#switcher-tabs').length > 0) {
      var ui = this.UIkit
      var sPageURL = window.location.href
      var sURLVariables = sPageURL.split('#')
      if(sURLVariables.length === 2) {
        var showTab = $('#switcher-tabs li').index($('li#'+sURLVariables[1]));
        ui.switcher($('#switcher-tabs')).show(showTab)
      }
    }

    $('.top-bar-search, .search-wrp .top-bar-icon, .search-mobile').click(function(e){
      e.preventDefault()
      if($(this).hasClass('open')) {
        $(this).removeClass('open');
        $('.top-search-form-wrp').fadeOut();
      } else {
        $(this).addClass('open');
        $('.top-search-form-wrp').fadeIn();
        $('.top-search-form-wrp .st-default-search-input').focus();
      }
    })

    $('.close-search-icon').click(function(e){
      e.preventDefault()
      $('.top-search-form-wrp').fadeOut();
      $('.top-bar-search').removeClass('open');
    })

    $('.share-btn').click(function(e){
      e.preventDefault();
      if($(this).hasClass('open')) {
        $(this).removeClass('open');
        $('.share-icons').removeClass('open');
      } else {
        $(this).addClass('open');
        $('.share-icons').addClass('open');
      }
    });

    $('body .body-content script').each(function(e){
      const text = $(this).text();
      window.eval(text)
    })

    $('body').on('click','.arrow-down, .notification-bell', function(e){
      e.preventDefault();
      $('.alerts-slider.expanded').fadeIn()
    })


    $('body').on('click','.close-icon', function(e){
      e.preventDefault();
      $('.alerts-slider.expanded').fadeOut()
    })

    if($('#switcher-nav').length > 0) {
      var ui = this.UIkit
      window.interval = window.setInterval(function() {
        ui.switcher('#switcher-nav').show('next');
      }, 10000);

      $('#switcher-nav').on('mouseenter focusin', function () {
        clearInterval(window.interval);
      });
    }

    if($('.landing-tabs').length > 0) {
      var ui = this.UIkit
      var isPaused = false;
      var underlineWidth = 0;
      if($('.tab-wrp').attr('data-time') != null && $('.tab-wrp').attr('data-time') != undefined) {
        var rotatorTime = Number($('.tab-wrp').attr('data-time'));
      } else {
        var rotatorTime = 10;
      }
      window.myInterval = window.setInterval(function() {
        underlineWidth ++;
        if (underlineWidth == 1000) {
          underlineWidth = 0;
          if ($('.uk-tab-bottom .uk-active').next().find('a')[0] != undefined) {
            $('.uk-tab-bottom .uk-active').next().find('a')[0].click();
          } else {
            $('.uk-tab-bottom li a')[0].click();
          }
          $('.uk-tab-bottom span').css('width', '0%');
        } else {
          $('.uk-tab-bottom .uk-active span').css('width', underlineWidth/10 + '%');
        }
      }, rotatorTime);
      $('.landing-tabs').on('mouseenter focusin', function() {
        isPaused = true;
        window.clearInterval(window.myInterval)
      });
      $('.landing-tabs').on('mouseleave focusout', function() {
        isPaused = false;
        window.myInterval = window.setInterval(function() {
          underlineWidth ++;
          if (underlineWidth == 1000) {
            underlineWidth = 0;
            if ($('.uk-tab-bottom .uk-active').next().find('a')[0] != undefined) {
              $('.uk-tab-bottom .uk-active').next().find('a')[0].click();
            } else {
              $('.uk-tab-bottom li a')[0].click();
            }
            $('.uk-tab-bottom span').css('width', '0%');
          } else {
            $('.uk-tab-bottom .uk-active span').css('width', underlineWidth/10 + '%');
          }
        }, rotatorTime);
      });
      $('.uk-tab-bottom a').click(function() {
        underlineWidth = 0;
        $('.uk-tab-bottom span').css('width', '0%');
      });
    }

    var ui = this.UIkit
    $('.expand-all').click(function(e){
      e.preventDefault();
      var index;
      if($(this).hasClass('open')) {
        $(this).text('Collapse all');
        $(this).removeClass('open')
        var accList = $(this).closest('.accordion-wrapp').find('.accordion-content > li');
        var accordionList = $(this).closest('.accordion-wrapp').find('.accordion-content');
        accList.each(function(){
          index = accList.index($(this));
          if(!$(this).hasClass('uk-open')) {
            console.log(ui)
            ui.accordion(accordionList).toggle(index, true);
          }
        })
      } else {
        $(this).addClass('open');
        $(this).text('Expand all');
        var accList = $(this).closest('.accordion-wrapp').find('.accordion-content > li');
        var accordionList = $(this).closest('.accordion-wrapp').find('.accordion-content');

        accList.each(function(){
          index = accList.index($(this));
          if($(this).hasClass('uk-open')) {
            ui.accordion(accordionList).toggle(index, true);
          }
        })
      }
    });


    setTimeout(function(){

    var iFrameID = document.getElementById('dynamic-height');
          if(iFrameID) {
                // here you can make the height, I delete it first, then I make it again
                iFrameID.height = "";
                iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
                console.log(iFrameID.height)
          }
        },2000)

    var mailHref =  $('.share-icons a[href^="mailto:"]').attr("href");
    $('.share-icons a[href^="mailto:"]').attr("href",mailHref+"?body="+window.location.href)

  }

  componentWillUnmount() {
    clearInterval(window.interval);
    clearInterval(window.myInterval)
  }


  render(props) {
    return (
       null
      )
  }
}

export default GlobalJs
