/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import FooterTest from "./footer"
import "./layout.css"
import '../styles/main.scss'
import GlobalJs from "./globaljs"
import BlueBar from "./bluebar"
import EocAlert from "./eocalert"

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="site-wrapper">
      <a className="skip-link" href="#site-content">Skip to content</a>
        <GlobalJs />
        <EocAlert></EocAlert>
        {children[0] && children[0].type ? (
          children[0].props && children[0].props.showMeeting && children[0].props.meetingLink ? (
            <BlueBar showMeeting={children[0].props.showMeeting} meetingLink={children[0].props.meetingLink} />
          ) : (
            <BlueBar showMeeting={false} meetingLink={false} />
          )

        ) : (
            <BlueBar showMeeting={false} meetingLink={false} />
        )}
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <div className="site-content" id="site-content" >
          {children}
        </div>
        <FooterTest />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
