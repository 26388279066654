import React, { Component } from "react"
import { Link } from "gatsby"
import LinkUrl from "../components/linkurl"


class mainMenu extends Component {
		constructor() {
			super()
		    var today = new Date(),
	        date = today.getFullYear() + '-' + ("0"+ (today.getMonth() + 1)).slice(-2) + '-' + ("0"+today.getDate()).slice(-2);
	        this.state = {
	          currentDate: date
	        }
		  }
		render() {
			const groupMenu = this.props.menu
			const manuName = this.props.menu_name
			const blockcBio = this.props.block_bio.edges
			const allPages = this.props.allPage
			const allNews = this.props.news
			const allEvents = this.props.events
			const socialNet = this.props.socialNetworks
			var printEvents = []
			if(allEvents !== undefined) {
				printEvents = allEvents.edges.filter(item =>
					item.node.field_start_date >= this.state.currentDate
				)
				printEvents = printEvents.slice(0,3)
			}

			var pagesArr = []
			allPages && allPages.edges && allPages.edges.map((page,key) => (
				page.node.context.drupal_nid !== null ? (
					pagesArr[page.node.context.drupal_nid ] = page.node.path
				) : (
					null
				)
			))

			var blockCurrent = []
			var parentMenu = []

			const filterMenu  = groupMenu.edges.filter(item => item.node.menu_name === manuName)

			filterMenu && filterMenu.map((colItem,colkey) => (
				colItem.node.drupal_parent_menu_item === null ? (
					parentMenu[colkey] = [],
					parentMenu[colkey]['parent'] = colItem,
					parentMenu[colkey]['submenu'] = []
				) : (
					null
				)
			))

			parentMenu && parentMenu.map((parentItem,parentkey) => (
				filterMenu && filterMenu.map((menuItem,menukey) => (
					 menuItem.node.drupal_parent_menu_item && menuItem.node.drupal_parent_menu_item.indexOf(parentItem.parent.node.drupal_id) !== -1 ? (
						parentMenu[parentkey]['submenu'][menukey] = [],
						parentMenu[parentkey]['submenu'][menukey]['item'] = menuItem,
						parentMenu[parentkey]['submenu'][menukey]['subitems'] = []
					) : (
						null
					)
				))
			))


			parentMenu && parentMenu.map((parentItem,parentkey) => (
				parentItem.submenu && parentItem.submenu.map((submenuItem,subkey) => (
					filterMenu && filterMenu.map((menuItem,menukey) => (
						 menuItem.node.drupal_parent_menu_item && menuItem.node.drupal_parent_menu_item.indexOf(submenuItem.item.node.drupal_id) !== -1 ? (
							parentMenu[parentkey]['submenu'][subkey]['subitems'][menukey] = menuItem
						) : (
							null
						)
					))
				))
			))

			return (
				<div className="uk-grid">
					{parentMenu && parentMenu.map((parentItem,pkey) => (
						<div className={manuName === 'news-social-media' && pkey === 5 ? ("uk-width-1-2@m") :("uk-width-1-4@m")} key={"col"+pkey}>
							<ul className="uk-nav uk-navbar-dropdown-nav">
								<li>
								{parentItem.submenu && parentItem.submenu.map((menuItem,menukey) => (
									menuItem.item.node.title.indexOf('bio block') !== -1 ? (
										blockCurrent = blockcBio.filter(block => block.node.info === menuItem.item.node.title),
										<ul className="uk-nav uk-navbar-dropdown-nav" key={"menu"+menukey}>
											{blockCurrent[0].node.relationships.field_bio_photo !== null && blockCurrent[0].node.relationships.field_bio_photo.relationships ? (
												<li><img src={blockCurrent[0].node.relationships.field_bio_photo.relationships.field_media_image.uri.url} alt={blockCurrent[0].node.field_bio_name} /></li>
											) : (
												null
											)}
											<li><div className="menu-person-name menu-headline">{blockCurrent[0].node.field_bio_name}</div></li>
											<li><span className="menu-person-title">{blockCurrent[0].node.field_bio_title}</span></li>
										</ul>
									) : (
										<ul key={"menu"+menukey} >
											{menuItem.item.node.link === null || menuItem.item.node.link.uri === 'internal:#' ? (
												<li><div className="menu-headline" dangerouslySetInnerHTML={{ __html: menuItem.item.node.title.replace('&','&amp;')}} /></li>
											) : (
												<li>
													{
													menuItem.item.node.link.uri.indexOf('http') !== -1 ? (
														<a href={menuItem.item.node.link.uri.replace('internal:','')} target="_blank" rel="noreferrer" className={menuItem.item.node.description !== null ? menuItem.item.node.description : ""}>
															<div className="menu-headline" dangerouslySetInnerHTML={{ __html: menuItem.item.node.title.replace('&','&amp;')}} />
														</a>
													) : (
														menuItem.item.node.link.uri && menuItem.item.node.link.uri.indexOf(':node/') !== -1 ? (
															<Link to={pagesArr[menuItem.item.node.link.uri.split('/')[1]]} className={menuItem.item.node.description !== null ? menuItem.item.node.description : ""}>
																<div className="menu-headline" dangerouslySetInnerHTML={{ __html: menuItem.item.node.title.replace('&','&amp;')}} />
															</Link>
														) : (
															<Link to={menuItem.item.node.link.uri.replace('internal:','')} className={menuItem.item.node.description !== null ? menuItem.item.node.description : ""}>
																<div className="menu-headline" dangerouslySetInnerHTML={{ __html: menuItem.item.node.title.replace('&','&amp;')}} />
															</Link>
														)
													)
												}
												</li>
											)}
											{menuItem.subitems && menuItem.subitems.map((subItem,subkey) => (
												<li key={"subitem"+ subkey}>
													<LinkUrl link={subItem.node.link.uri} allLinks={allPages} title={subItem.node.title} description={subItem.node.description} />
												</li>
											))}

											{menuItem.item.node.link.uri === 'internal:/news' ? (

												allNews !== undefined && allNews.edges && allNews.edges.map((news,key) => (
													<li key={"news"+key}>
														<Link to={news.node.path.alias}>{news.node.title}</Link>
													</li>
												))
											) : (
												null
											)}
											{menuItem.item.node.link.uri === 'internal:/news' ? (
												<li key={"news-all"}>
													<Link to="/news" className="green-arr">VIEW ALL</Link>
												</li>
											) : (
												null
											)}

											{menuItem.item.node.link.uri === 'internal:/events' ? (

												printEvents && printEvents.map((event,key) => (

													<li key={"event"+key}>
														<Link to={event.node.path.alias}>{event.node.title}</Link>
													</li>


												))

											) : (
												null
											)}
											{menuItem.item.node.link.uri === 'internal:/events' ? (
												<li key={"event-all"}>
													<Link to="/events" className="green-arr">VIEW ALL</Link>
												</li>
											) : (
												null
											)}
											{menuItem.item.node.link.uri === 'internal:#follow-us' && socialNet !== undefined ? (
												socialNet && socialNet.relationships.field_link_icon && socialNet.relationships.field_link_icon.map((link, index) => (
													link.field_link ? (
														link.relationships.field_icon ? (
															<li key={index} className="social-net"><a href={link.field_link.uri} rel="noreferrer" target="_blank"><img src={link.relationships.field_icon.uri.url} alt={link.field_link.title + " icon"} /><span>{link.field_link.title}</span></a></li>
														) : (
															null
														)
													) : (
														null
													)
												))
											) : (
												null
											)}

										</ul>
									)
								))}
								</li>
							</ul>
						</div>
					))}
				</div>
			)
		}
}

export default mainMenu
