import { StaticQuery, graphql, Link } from "gatsby"
import React ,{ Component }from "react"

class Meeting extends Component {
  constructor() {
    super();
    this.state = {
      meetengsData : []
    }
  }
  componentDidMount() {
    let handleData = (hairdressers) => {

        document.body.innerHTML = JSON.stringify(hairdressers);
    }

    let dataURL = "/assets/drupal_push_data.json";
    fetch( dataURL,{
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
         }
      )
    .then(res => res.json())
    .then(res => {
      this.setState({
        meetengsData: res
      })
    })

  }

  render(props) {
    if (this.state.meetengsData && this.state.meetengsData.live_meetings !== undefined && this.state.meetengsData.live_meetings.length > 0) {
      return (
          <div className="absolute-link-wrp" >
            <a className="watch" href={this.state.meetengsData.live_meetings[0].url} target="_blank">
              Watch live
            </a>
            {this.state.meetengsData.live_meetings[0].title !== '' ? (
              <span className="board-text">
                {this.state.meetengsData.live_meetings[0].title}
              </span>
            ) : (
              <span className="board-text">
                Today's Board Meeting
              </span>
            )}
            <a href={this.state.meetengsData.live_meetings[0].url} className="absolute-link" target="_blank">&nbsp;</a>
          </div>
        )

    } else {
      return null
    }
  }

}

export default Meeting
