import {StaticQuery, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo-pwc-cutup.svg"
import MainMenu from "../components/mainmenu"
import searchIcon from "../images/search-white.svg"
import closeIcon from "../images/close-icon-green.svg"
import share from "../images/share-color.svg"

const Header = ({ siteTitle }) => (
  <StaticQuery
      query={graphql`
        query {
          allSitePage(filter: {context: {drupal_nid: {ne: null}}}) {
            ...SitePageConnectionFragment
          }

          allBlockContentSocialLinksWithIcons(filter: {drupal_internal__id: {eq: 13}}) {
            edges {
              node {
                info
                drupal_internal__id
                relationships {
                  field_link_icon {
                    field_link {
                      title
                      uri
                    }
                    relationships {
                      field_icon {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          blockContentSocialLinksWithIcons {
            relationships {
              field_link_icon {
                field_link {
                  uri
                  title
                }
                relationships {
                  field_icon {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }

          allBlockContentMenuBio {
            edges {
              node {
                info
                field_bio_name
                field_bio_title
                relationships {
                  field_bio_photo {
                    relationships {
                      field_media_image {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          allMenuLinkContentMenuLinkContent(filter: {enabled: {eq: true}, menu_name: {in: ["main","info-services","get-invol","news-social-media"]} }, sort: {fields: weight, order: ASC}) {
            edges {
              node {
                title
                description
                menu_name
                drupal_parent_menu_item
                drupal_id
                link {
                  title
                  uri
                }
              }
            }
          }

          allNodeNews(limit: 3, sort: {order: DESC, fields: created}) {
            edges {
              node {
                path {
                  alias
                }
                title
              }
            }
          }

          allNodeEvent(sort: {fields: field_start_date, order: ASC}, filter: {}) {
            edges {
              node {
                field_end_date
                field_start_date
                title
                path {
                  alias
                }
              }
            }
          }
        }
      `}

      render={data => (
        <header className="site-header" >
          <nav className="uk-navbar-container uk-sticky" data-uk-navbar="mode: click" data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top; cls-active: uk-navbar-sticky;">
            <div className="top-search-form-wrp">
              <div className="container">
                <form className="search-bar-form">
                  <a className="close-search-icon" title="close icon" aria-label="close icon" href="#"><img src={closeIcon} alt="close-icon" /></a>
                  <label htmlFor="header-search">Srch</label>
                  <input type="text" id="header-search" name="header-search" placeholder="What are you looking for today?" className="st-default-search-input" />
                  <label htmlFor="header-search-btn">&nbsp;</label>
                  <input aria-label="header search btn" type="submit" id="header-search-btn" name="header-search-btn" value="Search" className="hero-search-button" />
                </form>
              </div>
            </div>
            <div className="container">
              <div className="uk-grid uk-flex-middle" data-uk-grid>
                <div className="uk-width-1-4@m uk-width-auto logo">
                  <Link to="/">
                    <img src={logo} alt="site-logo" />
                  </Link>
                </div>
                <div className="uk-width-3-4@m uk-width-auto main-nav">
                  <ul className="uk-navbar-nav main-nav-navbar">
                    <li>
                      <a className="main-nav-gov" title="My Gov" aria-label="My Gov" href="#">My Gov</a>
                      <div className="uk-navbar-dropdown">
                        <div className="container">
                          <MainMenu menu={data.allMenuLinkContentMenuLinkContent}  menu_name="main" block_bio={data.allBlockContentMenuBio} allPage={data.allSitePage} />
                        </div>
                      </div>
                    </li>

                    <li>
                      <a className="main-nav-info" title="Info & Services" aria-label="Info & Services" href="#">Info &amp; Services</a>
                      <div className="uk-navbar-dropdown">
                        <div className="container">
                          <MainMenu menu={data.allMenuLinkContentMenuLinkContent}  menu_name="info-services" block_bio={data.allBlockContentMenuBio} allPage={data.allSitePage} />
                        </div>
                      </div>
                    </li>
                    <li>
                      <a className="main-nav-get" title="Get Involved" aria-label="Get Involved" href="#">Get Involved</a>
                      <div className="uk-navbar-dropdown">
                        <div className="container">
                          <MainMenu menu={data.allMenuLinkContentMenuLinkContent}  menu_name="get-invol" block_bio={data.allBlockContentMenuBio} allPage={data.allSitePage} />
                        </div>
                      </div>
                    </li>
                    <li id="news-social-media">
                      <a className="main-nav-news" title="News & Social Media" aria-label="News & Social Media" href="#"> News &amp; Social Media</a>
                      <div className="uk-navbar-dropdown">
                        <div className="container">
                          <MainMenu menu={data.allMenuLinkContentMenuLinkContent} news={data.allNodeNews} events={data.allNodeEvent} socialNetworks={data.blockContentSocialLinksWithIcons} menu_name="news-social-media" block_bio={data.allBlockContentMenuBio} allPage={data.allSitePage} />
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a className="search-mobile" title="Search icon" aria-label="Search icon" href="#"><img src={searchIcon} alt="search-icon" /></a>
                  <a href="#" className="open-menu" aria-label="Open menu">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className="header-share">
            {data.allBlockContentSocialLinksWithIcons.edges.length > 0 ? (
              <ul className="share-icons">
                {data.allBlockContentSocialLinksWithIcons.edges[0].node.relationships.field_link_icon && data.allBlockContentSocialLinksWithIcons.edges[0].node.relationships.field_link_icon.map((icon,key) => (
                    icon.relationships.field_icon ? (
                      <li key={"icon"+key}><a href={icon.field_link.uri} target="_blank" rel="noreferrer"><img src={icon.relationships.field_icon.uri.url} alt={icon.field_link.title} /></a></li>
                    ) :(
                      null
                    )
                ))}
              </ul>
            ) : (
              null
            )}
            <a className="share-btn" title="share icon" aria-label="share icon" href="#">
              <img src={share} alt="share-ico" />
            </a>
          </div>
        </header>
      )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
